export const info = [
{ K: "1", Q: "+9+2=+8", S: "+6+2=+8", M: "", I: "" },
{ K: "2", Q: "+4-2=+9", S: "+7+2=+9", M: "2", I: "" },
{ K: "3", Q: "+9+1=+0", S: "+9-1=+8", M: "", I: "" },
{ K: "4", Q: "+9-5=+9", S: "+8-5=+3", M: "", I: "" },
{ K: "5", Q: "+7-4=+7", S: "+7=7=+7", M: "2", I: "" },
{ K: "6", Q: "+1+7=+9", S: "-1+1=+0", M: "2", I: "" },
{ K: "7", Q: "+4-2=+8", S: "+4+2=+6", M: "", I: "" },
{ K: "8", Q: "+1+1=+1", S: "+1x1=+1", M: "2", I: "" },
{ K: "9", Q: "+1+2=+2", S: "+1x2=+2", M: "2", I: "" },
{ K: "10", Q: "+5-4=+6", S: "+9-4=+5", M: "", I: "" },
{ K: "11", Q: "+3+2=+7", S: "+5+2=+7", M: "", I: "" },
{ K: "12", Q: "+4-1=+0", S: "+4+1=+5", M: "2", I: "" },
{ K: "13", Q: "+2-2=+6", S: "+2-2=+0", M: "", I: "" },
{ K: "14", Q: "+1-1=+9", S: "+1+1=+2", M: "2", I: "" },
{ K: "15", Q: "+6-0=+5", S: "+5+0=+5", M: "", I: "" },
{ K: "16", Q: "+1-4=+3", S: "+1=4-+3", M: "", I: "" },
{ K: "17", Q: "+6-5=+9", S: "+8-5=+3", M: "", I: "" },
{ K: "18", Q: "+1+3=+5", S: "+1+2=+3", M: "2", I: "" },
{ K: "19", Q: "+3-4=+7", S: "+3-4=-1", M: "", I: "" },
{ K: "20", Q: "+8+9=+3", S: "+0+9=+9", M: "", I: "" },
{ K: "21", Q: "+8+1=+6", S: "+8+1=+9", M: "", I: "" },
{ K: "22", Q: "+2-8=+7", S: "+2-9=-7", M: "", I: "" },
{ K: "23", Q: "+5-2=+1", S: "+3-2=+1", M: "", I: "" },
{ K: "24", Q: "+2+6=+2", S: "+2+0=+2", M: "", I: "" },
{ K: "25", Q: "+9+2=+0", S: "+8-2=+6", M: "2", I: "" },
{ K: "26", Q: "+6-1=+6", S: "+5+1=+6", M: "", I: "" },
{ K: "27", Q: "+3+1=+2", S: "+2+1=+3", M: "2", I: "" },
{ K: "28", Q: "+7+1=+3", S: "+7-1=+6", M: "2", I: "" },
{ K: "29", Q: "+7+1=+0", S: "+7-7=+0", M: "", I: "" },
{ K: "30", Q: "+1+1=+3", S: "+1-1=+0", M: "2", I: "" },
{ K: "31", Q: "+4-7=+0", S: "+1+7=+8", M: "2", I: "" },
{ K: "32", Q: "+7-5=+6", S: "+1+5=+6", M: "", I: "" },
{ K: "33", Q: "+7+1=+9", S: "+7-7=+0", M: "2", I: "" },
{ K: "34", Q: "+8-4=+2", S: "+8/4=+2", M: "", I: "" },
{ K: "35", Q: "+0-3=+6", S: "+9-3=+6", M: "", I: "" },
{ K: "36", Q: "+1+9=+5", S: "+1+5=+6", M: "", I: "" },
{ K: "37", Q: "+9+0=+8", S: "-9+9=+0", M: "2", I: "" },
{ K: "38", Q: "+6+7=+1", S: "+6+1=+7", M: "", I: "" },
{ K: "39", Q: "+1+9=+1", S: "+1+0=+1", M: "", I: "" },
{ K: "40", Q: "+0+1=+9", S: "+9-1=+8", M: "2", I: "" },
{ K: "41", Q: "+1+1=+6", S: "+7-1=+6", M: "", I: "" },
{ K: "42", Q: "+1+6=+4", S: "-1+5=+4", M: "", I: "" },
{ K: "43", Q: "+8+7=+9", S: "+8-3=+5", M: "2", I: "" },
{ K: "44", Q: "+9+4=+1", S: "-3+4=+1", M: "", I: "" },
{ K: "45", Q: "+1+8=+6", S: "+4+2=+6", M: "2", I: "" },
{ K: "46", Q: "+3+2=+8", S: "+3+6=+9", M: "2", I: "" },
{ K: "47", Q: "+4+4=+4", S: "+4=4=+4", M: "", I: "" },
{ K: "48", Q: "+9+3=+5", S: "+8-3=+5", M: "", I: "" },
{ K: "49", Q: "+2+7=+6", S: "+2+7=+9", M: "", I: "" },
{ K: "50", Q: "+1-7=+8", S: "+1-7=-6", M: "", I: "" },
{ K: "51", Q: "+8-7=+2", S: "+9-1=+8", M: "2", I: "" },
{ K: "52", Q: "+0-5=+6", S: "+0+5=+5", M: "", I: "" },
{ K: "53", Q: "+1+6=+8", S: "+1+8=+9", M: "", I: "" },
{ K: "54", Q: "+8+3=+4", S: "+8+3=+A", M: "", I: "" },
{ K: "55", Q: "+6+4=+1", S: "+6-5=+1", M: "2", I: "" },
{ K: "56", Q: "+6+2=+1", S: "+5+2=+7", M: "", I: "" },
{ K: "57", Q: "+6-1=+8", S: "+9-1=+8", M: "", I: "" },
{ K: "58", Q: "+9-1=+4", S: "+3+1=+4", M: "", I: "" },
{ K: "59", Q: "+9+1=+8", S: "+4+4=+8", M: "2", I: "" },
{ K: "60", Q: "+8-4=+3", S: "+5+4=+9", M: "2", I: "" },
{ K: "61", Q: "+3+8=+6", S: "-3+8=+5", M: "", I: "" },
{ K: "62", Q: "+7+8=+3", S: "+1+8=+9", M: "", I: "" },
{ K: "63", Q: "+6-1=+3", S: "+6-1=+5", M: "", I: "" },
{ K: "64", Q: "+4-3=+8", S: "+A-3=+8", M: "", I: "" },
{ K: "65", Q: "+2+7=+0", S: "+2+7=+9", M: "", I: "" },
{ K: "66", Q: "+3+5=+7", S: "+2+5=+7", M: "", I: "" },
{ K: "67", Q: "+1x7=+1", S: "+7/7=+1", M: "", I: "" },
{ K: "68", Q: "+3+4=+9", S: "+5+4=+9", M: "", I: "" },
{ K: "69", Q: "+7+0=+9", S: "+1+8=+9", M: "", I: "" },
{ K: "70", Q: "+2+2=+2", S: "+2-2=+0", M: "2", I: "" },
{ K: "71", Q: "+7+5=+5", S: "+1+5=+6", M: "", I: "" },
{ K: "72", Q: "+9-7=+6", S: "+9-1=+8", M: "", I: "" },
{ K: "73", Q: "+3+9=+5", S: "+9-3=+6", M: "2", I: "" },
{ K: "74", Q: "+6-7=+2", S: "+9-7=+2", M: "", I: "" },
{ K: "75", Q: "+1+7=+6", S: "+1+4=+5", M: "2", I: "" },
{ K: "76", Q: "+9x3=+4", S: "+9/3=+3", M: "2", I: "" },
{ K: "77", Q: "+0-1=+3", S: "+6-1=+5", M: "2", I: "" },
{ K: "78", Q: "+8-3=+3", S: "+0+3=+3", M: "", I: "" },
{ K: "79", Q: "+5+9=+9", S: "+5+3=+8", M: "", I: "" },
{ K: "80", Q: "+3-7=+4", S: "+3+1=+4", M: "", I: "" },
{ K: "81", Q: "+1-5=+8", S: "+1+5=+6", M: "", I: "" },
{ K: "82", Q: "+6-1=+6", S: "+5+1=+6", M: "", I: "" },
{ K: "83", Q: "+0-1=+7", S: "+0+1=+1", M: "", I: "" },
{ K: "84", Q: "+5+3=+7", S: "+5+2=+7", M: "", I: "" },
{ K: "85", Q: "+5-3=+9", S: "+6-3=+3", M: "", I: "" },
{ K: "86", Q: "+7+5=+1", S: "+7-6=+1", M: "", I: "" },
{ K: "87", Q: "+9+3=+7", S: "+9-8=+1", M: "2", I: "" },
{ K: "88", Q: "+9-5=+8", S: "+9-9=+0", M: "", I: "" },
{ K: "89", Q: "+1-1=+7", S: "+1=1=+1", M: "", I: "" },
{ K: "90", Q: "+7+1=+0", S: "-1+1=+0", M: "", I: "" },
{ K: "91", Q: "+1+8=+7", S: "+7+0=+7", M: "", I: "" },
{ K: "92", Q: "+1-9=+8", S: "+1=9-+8", M: "", I: "" },
{ K: "93", Q: "+7-5=+8", S: "+7-9=-2", M: "2", I: "" },
{ K: "94", Q: "+3-1=+5", S: "+3+1=+4", M: "2", I: "" },
{ K: "95", Q: "+8-5=+0", S: "+6-6=+0", M: "", I: "" },
{ K: "96", Q: "+4+2=+3", S: "+4+2=+2", M: "", I: "" },
{ K: "97", Q: "+9+7=+7", S: "+0+7=+7", M: "", I: "" },
{ K: "98", Q: "+0+7=+1", S: "+8-7=+1", M: "", I: "" },
{ K: "99", Q: "+7-1=+9", S: "+7-1=+6", M: "", I: "" },
{ K: "100", Q: "+3+2=+7", S: "+5+2=+7", M: "", I: "" },
{ K: "101", Q: "+3-2=+6", S: "+3+2=+5", M: "", I: "" },
{ K: "102", Q: "+1+9=+2", S: "+1+5=+6", M: "2", I: "" },
{ K: "103", Q: "+1-6=+5", S: "+7-2=+5", M: "2", I: "" },
{ K: "104", Q: "+1+9=+2", S: "-1+3=+2", M: "", I: "" },
{ K: "105", Q: "+5-4=+6", S: "+9-4=+5", M: "", I: "" },
{ K: "106", Q: "+9-7=+1", S: "+4-3=+1", M: "2", I: "" },
{ K: "107", Q: "+9+5=+6", S: "+3+5=+8", M: "", I: "" },
{ K: "108", Q: "+1+8=+5", S: "+7+2=+9", M: "2", I: "" },
{ K: "109", Q: "+5+4=+2", S: "+6-4=+2", M: "", I: "" },
{ K: "110", Q: "+5-2=+4", S: "+5+2=+7", M: "2", I: "" },
{ K: "111", Q: "+0+8=+1", S: "+8-9=-1", M: "2", I: "" },
{ K: "112", Q: "+1-3=+2", S: "+1=3-+2", M: "", I: "" },
{ K: "113", Q: "+7+5=+7", S: "+1+6=+7", M: "", I: "" },
{ K: "114", Q: "+1-4=+5", S: "+7-1=+6", M: "2", I: "" },
{ K: "115", Q: "+7-0=+6", S: "17-A=+6", M: "2", I: "" },
{ K: "116", Q: "+1-6=+1", S: "+1-0=+1", M: "", I: "" },
{ K: "117", Q: "+2-7=+3", S: "+2+1=+3", M: "", I: "" },
{ K: "118", Q: "+1+1=+5", S: "+1-1=+0", M: "2", I: "" },
{ K: "119", Q: "+2+3=+4", S: "+2+2=+4", M: "", I: "" },
{ K: "120", Q: "+3+0=+8", S: "+9+0=+9", M: "", I: "" },
{ K: "121", Q: "+4+7=+9", S: "-4+7=+3", M: "", I: "" },
{ K: "122", Q: "+5-2=+5", S: "+5-2=+3", M: "", I: "" },
{ K: "123", Q: "+7-2=+4", S: "+7-3=+4", M: "", I: "" },
{ K: "124", Q: "+7+7=+0", S: "+7+1=+8", M: "", I: "" },
{ K: "125", Q: "+7-0=+1", S: "+1+0=+1", M: "", I: "" },
{ K: "126", Q: "+0+7=+6", S: "+8+1=+9", M: "2", I: "" },
{ K: "127", Q: "+9+2=+7", S: "+9-8=+1", M: "2", I: "" },
{ K: "128", Q: "+5-0=+9", S: "+5+0=+5", M: "", I: "" },
{ K: "129", Q: "+9-4=+3", S: "+9-4=+5", M: "", I: "" },
{ K: "130", Q: "+5+0=+3", S: "+5+0=+5", M: "", I: "" },
{ K: "131", Q: "+1+8=+7", S: "+1-8=-7", M: "", I: "" },
{ K: "132", Q: "+3-6=+8", S: "+3+5=+8", M: "", I: "" },
{ K: "133", Q: "+1+9=+7", S: "+1+3=+4", M: "2", I: "" },
{ K: "134", Q: "+1+6=+9", S: "+7+2=+9", M: "2", I: "" },
{ K: "135", Q: "+4-1=+4", S: "+4/1=+4", M: "", I: "" },
{ K: "136", Q: "+0+8=+5", S: "+0+6=+6", M: "", I: "" },
{ K: "137", Q: "+2-9=+7", S: "+2+5=+7", M: "", I: "" },
{ K: "138", Q: "+0+7=+1", S: "+8-7=+1", M: "", I: "" },
{ K: "139", Q: "+6-2=+7", S: "+5+2=+7", M: "", I: "" },
{ K: "140", Q: "+3-1=+4", S: "+5-1=+4", M: "", I: "" },
{ K: "141", Q: "+5-2=+1", S: "+3-2=+1", M: "", I: "" },
{ K: "142", Q: "+3-3=+8", S: "+9-3=+6", M: "", I: "" },
{ K: "143", Q: "+7-7=+1", S: "+7/1=+7", M: "2", I: "" },
{ K: "144", Q: "+1-5=+4", S: "+1=5-+4", M: "", I: "" },
{ K: "145", Q: "+2+4=+3", S: "+2+7=+9", M: "2", I: "" },
{ K: "146", Q: "+4-7=+3", S: "+4+1=+5", M: "2", I: "" },
{ K: "147", Q: "+5-6=+1", S: "+6-5=+1", M: "", I: "" },
{ K: "148", Q: "+5-6=+7", S: "+5-6=-1", M: "", I: "" },
{ K: "149", Q: "+2+7=+7", S: "+8-7=+1", M: "2", I: "" },
{ K: "150", Q: "+1-3=+3", S: "+1=3-+2", M: "2", I: "" },
{ K: "151", Q: "+6+1=+5", S: "+5+1=+6", M: "", I: "" },
{ K: "152", Q: "+5-5=+1", S: "+5/5=+1", M: "", I: "" },
{ K: "153", Q: "+0+1=+8", S: "+8+1=+9", M: "", I: "" },
{ K: "154", Q: "+5-1=+9", S: "+6-1=+5", M: "", I: "" },
{ K: "155", Q: "+1-3=+8", S: "-1+3=+2", M: "2", I: "" },
{ K: "156", Q: "+5+1=+5", S: "+6-1=+5", M: "", I: "" },
{ K: "157", Q: "+9-1=+0", S: "+5+1=+6", M: "2", I: "" },
{ K: "158", Q: "+8-1=+2", S: "+9-7=+2", M: "", I: "" },
{ K: "159", Q: "+9+5=+5", S: "+0+5=+5", M: "", I: "" },
{ K: "160", Q: "+6-0=+5", S: "+5+0=+5", M: "", I: "" },
{ K: "161", Q: "+6+2=+6", S: "+8-2=+6", M: "", I: "" },
{ K: "162", Q: "+5-5=+7", S: "+6-5=+1", M: "", I: "" },
{ K: "163", Q: "+3+3=+0", S: "+9-3=+6", M: "2", I: "" },
{ K: "164", Q: "+3-8=+7", S: "+9-8=+1", M: "", I: "" },
{ K: "165", Q: "+1+9=+8", S: "+1+8=+9", M: "", I: "" },
{ K: "166", Q: "+5+8=+5", S: "+6-0=+6", M: "2", I: "" },
{ K: "167", Q: "+7+1=+9", S: "+3+1=+4", M: "2", I: "" },
{ K: "168", Q: "+3-1=+3", S: "+3/1=+3", M: "", I: "" },
{ K: "169", Q: "+0+1=+7", S: "+8-1=+7", M: "", I: "" },
{ K: "170", Q: "+2-4=+2", S: "+2=4-+2", M: "2", I: "" },
{ K: "171", Q: "+5+2=+11", S: "+6-2=+4", M: "2", I: "" },
{ K: "172", Q: "+3+5=+11", S: "+9-5=+4", M: "2", I: "" },
{ K: "173", Q: "+9+1=+0", S: "+9-1=+8", M: "", I: "" },
{ K: "174", Q: "+9+3=+6", S: "+5+3=+8", M: "", I: "" },
{ K: "175", Q: "+9-4=+7", S: "+3+4=+7", M: "", I: "" },
{ K: "176", Q: "+6+4=+4", S: "+8-4=+4", M: "", I: "" },
{ K: "177", Q: "+1+6=+9", S: "+A-6=+5", M: "2", I: "" },
{ K: "178", Q: "+5+2=+4", S: "+6-2=+4", M: "", I: "" },
{ K: "179", Q: "+7+6=+1", S: "+1+6=+7", M: "", I: "" },
{ K: "180", Q: "+1+4=+9", S: "-1+4=+3", M: "", I: "" },
{ K: "181", Q: "+5+1=+7", S: "+5-1=+4", M: "2", I: "" },
{ K: "182", Q: "+8+5=+2", S: "+8-6=+2", M: "", I: "" },
{ K: "183", Q: "+1-6=+5", S: "+1=6-+5", M: "", I: "" },
{ K: "184", Q: "+3+5=+6", S: "+3+3=+6", M: "", I: "" },
{ K: "185", Q: "+1+7=+5", S: "+1=7-+6", M: "2", I: "" },
{ K: "186", Q: "+6-1=+9", S: "+5+1=+6", M: "", I: "" },
{ K: "187", Q: "+8-2=+4", S: "+8/2=+4", M: "", I: "" },
{ K: "188", Q: "+5-8=+6", S: "+6+0=+6", M: "", I: "" },
{ K: "189", Q: "+3+4=+1", S: "+3=4-+1", M: "", I: "" },
{ K: "190", Q: "+1+8=+6", S: "+1+8=+9", M: "", I: "" },
{ K: "191", Q: "+1+1=+9", S: "-1+1=+0", M: "", I: "" },
{ K: "192", Q: "+5x2=+3", S: "+6/2=+3", M: "", I: "" },
{ K: "193", Q: "+3-9=+3", S: "+3-0=+3", M: "", I: "" },
{ K: "194", Q: "+3-4=+1", S: "+5-4=+1", M: "", I: "" },
{ K: "195", Q: "+0-1=+7", S: "+0-1=-1", M: "", I: "" },
{ K: "196", Q: "+5-7=+2", S: "+5=7-+2", M: "", I: "" },
{ K: "197", Q: "+1+6=+8", S: "+4+5=+9", M: "2", I: "" },
{ K: "198", Q: "+1-7=+2", S: "+1+1=+2", M: "", I: "" },
{ K: "199", Q: "+0+7=+7", S: "+0-7=-7", M: "", I: "" },
{ K: "200", Q: "+7-1=+1", S: "+1=1=+1", M: "", I: "" },
{ K: "201", Q: "+6+3=+8", S: "+6+2=+8", M: "", I: "" },
{ K: "202", Q: "+1+3=+2", S: "+7-5=+2", M: "2", I: "" },
{ K: "203", Q: "+7+7=+4", S: "+A-7=+4", M: "2", I: "" },
{ K: "204", Q: "+1+3=+2", S: "+1-3=-2", M: "", I: "" },
{ K: "205", Q: "+1-7=+8", S: "+7-7=+0", M: "", I: "" },
{ K: "206", Q: "+9+2=+6", S: "+5+3=+8", M: "2", I: "" },
{ K: "207", Q: "+3+9=+6", S: "+3+5=+8", M: "", I: "" },
{ K: "208", Q: "+1-6=+4", S: "+1+3=+4", M: "2", I: "" },
{ K: "209", Q: "+7-7=+5", S: "+7-1=+6", M: "", I: "" },
{ K: "210", Q: "+2+1=+7", S: "+0+1=+1", M: "2", I: "" },
{ K: "211", Q: "+1-6=+7", S: "+1+0=+1", M: "2", I: "" },
{ K: "212", Q: "+1-4=+6", S: "+1+4=+5", M: "", I: "" },
{ K: "213", Q: "+5+8=+6", S: "+6+0=+6", M: "", I: "" },
{ K: "214", Q: "+1-2=+6", S: "+7-2=+5", M: "", I: "" },
{ K: "215", Q: "+5+5=+4", S: "+9-5=+4", M: "", I: "" },
{ K: "216", Q: "+6-1=+7", S: "+0-1=-1", M: "2", I: "" },
{ K: "217", Q: "+5+4=+3", S: "+9-4=+5", M: "2", I: "" },
{ K: "218", Q: "+5+0=+6", S: "+6-0=+6", M: "", I: "" },
{ K: "219", Q: "+0+0=+8", S: "+8-0=+8", M: "", I: "" },
{ K: "220", Q: "+3-4=+2", S: "+9-7=+2", M: "2", I: "" },
{ K: "221", Q: "+9-1=+6", S: "+5+1=+6", M: "", I: "" },
{ K: "222", Q: "+9+5=+0", S: "+3+5=+8", M: "", I: "" },
{ K: "223", Q: "+6-4=+9", S: "+5+4=+9", M: "", I: "" },
{ K: "224", Q: "+1+8=+2", S: "+A-9=+2", M: "2", I: "" },
{ K: "225", Q: "+7+8=+2", S: "-7+9=+2", M: "", I: "" },
{ K: "226", Q: "+4-5=+6", S: "+A-5=+6", M: "", I: "" },
{ K: "227", Q: "+1-2=+7", S: "+1-2=-1", M: "", I: "" },
{ K: "228", Q: "+7+6=+0", S: "+1+8=+9", M: "2", I: "" },
{ K: "229", Q: "+1+1=+0", S: "+7-1=+6", M: "2", I: "" },
{ K: "230", Q: "+7+3=+2", S: "-1+3=+2", M: "", I: "" },
{ K: "231", Q: "+5+2=+4", S: "+2+2=+4", M: "2", I: "" },
{ K: "232", Q: "+1+1=+7", S: "+7/1=+7", M: "2", I: "" },
{ K: "233", Q: "+4+4=+5", S: "+4-4=+0", M: "2", I: "" },
{ K: "234", Q: "+2-3=+7", S: "+2-3=-1", M: "", I: "" },
{ K: "235", Q: "+4-1=+2", S: "+4-1=+3", M: "", I: "" },
{ K: "236", Q: "+3+5=+7", S: "+2+5=+7", M: "", I: "" },
{ K: "237", Q: "+5+8=+5", S: "+6-0=+6", M: "", I: "" },
{ K: "238", Q: "+5-1=+8", S: "+5+1=+6", M: "", I: "" },
{ K: "239", Q: "+6+1=+0", S: "+9-1=+8", M: "2", I: "" },
{ K: "240", Q: "+4+9=+1", S: "+4+3=+7", M: "", I: "" },
{ K: "241", Q: "+1-6=+4", S: "+1-5=-4", M: "", I: "" },
{ K: "242", Q: "+4+5=+7", S: "+4+3=+7", M: "", I: "" },
{ K: "243", Q: "+4-6=+5", S: "+A-6=+5", M: "", I: "" },
{ K: "244", Q: "+5-7=+5", S: "+6-1=+5", M: "", I: "" },
{ K: "245", Q: "+2-3=+3", S: "+2=5-+3", M: "2", I: "" },
{ K: "246", Q: "+3-4=+6", S: "+9-4=+5", M: "", I: "" },
{ K: "247", Q: "+1-7=+9", S: "+A-7=+4", M: "", I: "" },
{ K: "248", Q: "+6+5=+6", S: "+6+3=+9", M: "", I: "" },
{ K: "249", Q: "+6+6=+7", S: "+6+5=+A", M: "2", I: "" },
{ K: "250", Q: "+7+8=+8", S: "+3+5=+8", M: "2", I: "" },
{ K: "251", Q: "+6-6=+4", S: "+6+5=+A", M: "2", I: "" },
{ K: "252", Q: "+7-4=0", S: "+7+1=+8", M: "2", I: "" },
{ K: "253", Q: "+2-7=+8", S: "+2+7=+9", M: "", I: "" },
{ K: "254", Q: "+9+1=+2", S: "+9-7=+2", M: "", I: "" },
{ K: "255", Q: "+3+3=+5", S: "+3+2=+5", M: "", I: "" },
{ K: "256", Q: "+2+8=+6", S: "+2+6=+8", M: "", I: "" },
{ K: "257", Q: "+9+5=+9", S: "+9-6=+3", M: "", I: "" },
{ K: "258", Q: "+1+2=+5", S: "+1+2=+3", M: "", I: "" },
{ K: "259", Q: "+9-8=+2", S: "+8-6=+2", M: "", I: "" },
{ K: "260", Q: "+1+5=+9", S: "+1+5=+6", M: "", I: "" }
]
